import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import importVideo from '@/components/importVideo'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'page_title',
      name: 'input',
      label: i18n.t('form.decoration.pageTitle'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'customized_landing_page_elements_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.decoration.customizedPageElement'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'remote_image_url',
        name: 'image',
        value: [],
        label: i18n.t('form.common.image'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          limit: 1,
          imageName: 'remote_image_url'
          // rules: [
          //   { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.image') }) }
          // ]
        }
      }, {
        span: 12,
        prop: 'element_type',
        name: 'select',
        options: [],
        label: i18n.t('form.coupon.type'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      },
      {
        span: 24,
        prop: 'video',
        name: 'component',
        label: i18n.t('form.common.video'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          name: importVideo,
          visible: 'element_type',
          visibleValue: 1
        }
      }, {
        span: 12,
        prop: 'link',
        name: 'input',
        label: i18n.t('form.common.link'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          visible: 'element_type',
          visibleValue: '0,2'
        }
      }, {
        span: 12,
        prop: 'display_order',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.displayOrder'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }]
  }]
}
