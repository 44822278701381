// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPageEditDetails, getPageCollections, putPageFormData } from '@/services/decoration.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import Utils from '@/utils/Utils'
import _ from 'lodash'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPageCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[1].createDateFirstItem[1].options = res.data.element_types
        }
      })
      getPageEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.customized_landing_page
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      const type = {
        0: 'link',
        1: 'video',
        2: 'external_link'
      }
      this.$store.state.app.loading = this.$loading(loading)
      const obj = _.cloneDeep(data)
      if (obj.customized_landing_page_elements_attributes.length > 0) {
        obj.customized_landing_page_elements_attributes.forEach((res, index) => {
          if (!Utils.isNull(res.element_type)) res.element_type = type[res.element_type]
          if (Utils.isNull(res.video)) delete obj.customized_landing_page_elements_attributes[index].video
        })
      }
      putPageFormData(Utils.toFormData(obj, 'customized_landing_page'), this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'pageList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
